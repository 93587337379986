import React from "react";
import axios from "axios";
// import { Link } from "gatsby";
import LoadingGif1 from "../../assets/gifs/loading_1.gif";
import LoadingGif2 from "../../assets/gifs/loading_2.gif";
import LoadingGif3 from "../../assets/gifs/loading_3.gif";
import LoadingGif4 from "../../assets/gifs/loading_4.gif";
import Select from "react-select";

var Api_url = process.env.GATSBY_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
  headers: {
    "Content-Type": "application/json; text/html; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

class NonMemberDonate extends React.Component {

  constructor() {
    super();
    this.state = {
      YearwiseTitleId: "",
      TitleId: "",
      TitleDescription: "",
      TitleAmount: "",
      TitleOptions: [],
      ZoneId: '',
      ZoneOptions: [],
      LomOptions: [],
      LomId: "",
      MemberId: "",
      MemberFirstName: "",
      MemberLastName: "",
      EmailId: "",
      MobileNo: "",
      Address: "",
      Area: "",
      PanNumberStatus: 0,
      PanNumber: "",
      PanCardStatus: 0,
      PanCardImage: null,
      AadharNumber: "",
      MembershipId: "",
      StatesId: '',
      StatesIdOptions: [],
      DistrictId: '',
      DistrictIdOptions: [],
      CityId: '',
      CityIdOptions: [],
      Pincode: "",
      SuccessMsg: "",
      ErrorMsg: "",
      MemberErrorMsg: "",
      LoadingFlag: false,
      TitleLoadingFlag: false,
      MemberLoadingFlag: false,
      SubmitFlag: false,
      PaymentLoadingFlag: false,
      Order: {
        customer_name: "Jothi Raj S",
        customer_email_id: "msjothiraj@gmail.com",
        customer_mobile_no: 7418480701,
        back_url: "http://localhost:8000/contact",
        rzp_action: "#",
        rzp_account_id: "acc_HpPpISS06eocAp",
        rzp_key: "rzp_test_partner_GOPustBDrPuVUw",
        rzp_amount: 100,
        rzp_order_id: "order_Hu7UTxbBmpZzh5",
        rzp_buttontext: "Pay now",
        rzp_name: "JCI India Foundation",
        rzp_description: "HGF",
        rzp_image: "http://localhost/projects/jci_india_erp/jci_india_erp_public/lib/assets/images/logo.png",
        rzp_color: "#0096d5"
      },
    }
  };

  handleChange(e) {
    console.log("handleChange called ");
    console.log("name=" + e.target.name + ",value=" + e.target.value);
    // this.setState({ LomId: event.target.value });
    this.setState({ [e.target.name]: e.target.value });
  }

  onFileChange = e => {
    // Update the state 
    console.log('onFileChange called');
    var name = e.target.name;
    var file = e.target.files[0];
    console.log({ file });
    this.setState({ [name]: file });
  };

  getAllTitles = async () => {
    console.log("getAllTitles called ");
    var url = Api_url + `/api/wm/yearwise/titles/options`;
    console.log("url=" + url);
    var postData = {
      additional: {
        UserInterface: 1,
        YearCode: 11,
      },
    };
    this.setState({ LoadingFlag: true });
    console.log("postData=" + JSON.stringify(postData));
    // this.setState({ isLoading: true });
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        console.log("options=" + JSON.stringify(options));
        this.setState({ LoadingFlag: false });
        this.setState({ TitleOptions: options });
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.YearwiseTitleId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  getTitleDetails = async e => {
    console.log("getTitleDetails called ");
    var YearwiseTitleId = e.value;
    console.log("YearwiseTitleId=" + YearwiseTitleId);
    this.setState({ YearwiseTitleId: YearwiseTitleId, TitleId: "", TitleDescription: "", TitleAmount: "", TitleLoadingFlag: true });
    var url = Api_url + `/api/wm/yearwise/titles/show`;
    console.log("url=" + url);
    var postData = {
      "UserInterface": 1,
      "YearwiseTitleId": YearwiseTitleId
    };
    console.log("postData=" + JSON.stringify(postData));
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = [];
        /*
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        */
        console.log("options=" + JSON.stringify(options));
        this.setState({ TitleLoadingFlag: false, TitleId: result.TitleId, TitleDescription: result.TitleDescription, TitleAmount: result.TitleAmountTxt });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  getZones = async () => {
    console.log("getZones called ");
    var url = Api_url + `/api/year-wise-lom/zone-options`;
    console.log("url=" + url);
    var postData = {
      additional: {
        UserInterface: 4,
      },
    };
    console.log("postData=" + JSON.stringify(postData));
    this.setState({ MemberLoadingFlag: true });
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        console.log("options=" + JSON.stringify(options));
        this.setState({ ZoneOptions: options, MemberLoadingFlag: false });
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  fetchLoms = async e => {
    console.log("fetchLoms called ");
    var ZoneId = e.value;
    this.setState({ ZoneId: ZoneId, LomId: "", LomOptions: [], MemberLoadingFlag: true });
    var url = Api_url + `/api/year-wise-lom/lom-options`;
    console.log("url=" + url + ", ZoneId=" + ZoneId);
    var postData = {
      additional: {
        UserInterface: 2,
        ZoneCode: ZoneId,
      },
    };
    console.log("postData=" + JSON.stringify(postData));
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        console.log("options=" + JSON.stringify(options));
        this.setState({ LomOptions: options, MemberLoadingFlag: false });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  getMemberDetails = async event => {
    console.log("getMemberDetails called ");
    event.preventDefault();

    var url = Api_url + `/api/members/search`;
    console.log("url=" + url);

    this.setState({ MemberLoadingFlag: true, SuccessMsg: "", MemberErrorMsg: "", MemberId: "", MemberFirstName: "", MemberLastName: "", MobileNo: "", EmailId: "", Address: "", Area: "", Pincode: "" });

    var postData = {
      UserInterface: 1,
      ZoneCode: this.state.ZoneId,
      LomCode: this.state.LomId,
      MembershipId: this.state.MembershipId,
    };
    console.log("postData=" + JSON.stringify(postData));

    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", JSON.stringify(res));
        var flag = res.data.flag;
        var records = res.data.records;
        console.log("flag=" + flag + ", records=" + JSON.stringify(records));
        this.setState({ MemberLoadingFlag: false });
        if (flag) {
          // var redirect_url = records.url;
          console.log("records=" + JSON.stringify(records));
          // window.location.replace(redirect_url);
          this.setState({ MemberId: records.MemberId, MemberFirstName: records.FirstName, MemberLastName: records.LastName, MobileNo: records.MobileNo, EmailId: records.EmailId, Address: records.AddressTxt, Area: records.City, Pincode: records.City });
        } else {
          this.setState({ MemberErrorMsg: res.data.output });
        }
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.YearwiseTitleId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });

  };

  validateForm = async event => {
    console.log("validateForm called ");
    event.preventDefault();

    var url = Api_url + `/api/payment/title-member/validate`;
    console.log("url=" + url);

    this.setState({ SubmitFlag: true, SuccessMsg: "", ErrorMsg: "" });

    var ZoneId = this.state.LomId == "" ? "" : this.state.ZoneId;
    var LomId = this.state.ZoneId == "" ? "" : this.state.LomId;

    let postData = new FormData();
    postData.append("UserInterface", 2);
    postData.append("TitleName", this.state.YearwiseTitleId);
    postData.append("ZoneName", ZoneId);
    postData.append("LomName", LomId);
    postData.append("MembershipId", this.state.MembershipId);
    postData.append("MemberName", this.state.MemberId);
    postData.append("MemberFirstName", this.state.MemberFirstName);
    postData.append("MemberLastName", this.state.MemberLastName);
    postData.append("EmailId", this.state.EmailId);
    postData.append("MobileNo", this.state.MobileNo);
    postData.append("Address", this.state.Address);
    postData.append("Area", this.state.Area);
    postData.append("StatesName", this.state.StatesId);
    postData.append("DistrictName", this.state.DistrictId);
    postData.append("CityName", this.state.CityId);
    postData.append("Pincode", this.state.Pincode);
    postData.append("PanNumberStatus", this.state.PanNumberStatus);
    postData.append("PanNumber", this.state.PanNumber);
    postData.append("PanCardStatus", this.state.PanCardStatus);
    postData.append("AadharNumber", this.state.AadharNumber);

    console.log("postData=" + JSON.stringify(postData));

    var PanCardImage = this.state.PanCardImage;
    console.log({ PanCardImage });

    if (PanCardImage == null || PanCardImage == undefined || PanCardImage == '') {

      postData.append(
        "PanCardImage",
        ''
      );
    } else {
      var PanCardImageName = this.state.PanCardImage.name;
      console.log({ PanCardImageName });

      postData.append(
        "PanCardImage",
        PanCardImage,
        PanCardImageName
      );
    }

    var axiosConfig1 = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    await axios
      .post(url, postData, axiosConfig1)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        this.setState({ SubmitFlag: false });
        var flag = res.data.flag;
        var records = res.data.records;
        console.log("flag=" + flag + ", records=" + JSON.stringify(records));
        if (flag) {
          // var redirect_url = records.url;
          // console.log("redirect_url=" + redirect_url);
          // window.location.replace(redirect_url);
          this.setState({ Order: records.Order });
          this.makePayment();
        } else {
          this.setState({ ErrorMsg: res.data.output });
        }
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.YearwiseTitleId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  makePayment = async () => {
    console.log("makePayment called");
    // ev.preventDefault();
    var order = this.state.Order;
    var back_url = order.back_url;
    console.log("back_url=" + back_url + ", order=" + JSON.stringify(order));
    this.setState({ PaymentLoadingFlag: true });
    const thisIns = this;
    const options = {
      key: order.rzp_key,
      account_id: order.rzp_account_id,
      order_id: order.rzp_order_id,
      amount: order.rzp_amount,
      name: order.rzp_name,
      currency: "INR",
      description: order.rzp_description,
      image: order.rzp_image,
      theme: {
        color: order.rzp_color,
      },
      prefill: {
        name: order.customer_name,
        email: order.customer_email_id,
        contact: order.customer_mobile_no,
      },
      notes: {
        address: "",
      },
      // callback_url: "http://domain.com/#/about/" + this.order_id,
      redirect: false,
      handler: function (response) {
        console.log("razorpay_payment_id=" + response.razorpay_payment_id);
        thisIns.setState({ PaymentLoadingFlag: false });
        window.location.replace(back_url);
        /*
        thisIns.$session.remove("checkout");
        thisIns.SubmitFlag = true;
        thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        thisIns.pageRedirect({}, "/payment/success");
        */
      },
      "modal": {
        "ondismiss": function () {
          console.log('Checkout form closed');
          thisIns.setState({ PaymentLoadingFlag: false });
        }
      }
    };

    const rzp1 = new window.Razorpay(options);
    // const thisIns = this;
    // var rzp1 = new Razorpay();
    rzp1.open();
  };

  getStateOptions = async () => {
    console.log("getStateOptions called ");
    var url = Api_url + `/api/states-options`;
    console.log("url=" + url);
    var postData = {
      additional: {
        UserInterface: 1,
      }
    };
    console.log("postData=" + JSON.stringify(postData));
    this.setState({ MemberLoadingFlag: true });
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        console.log("options=" + JSON.stringify(options));
        this.setState({ StatesIdOptions: options, MemberLoadingFlag: false });
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  getDistrictIdOptions = async (e) => {
    console.log("getDistrictIdOptions called ");
    var ElementId = e.value;
    this.setState({ StatesId: ElementId, DistrictId: '', CityId: '', DistrictIdOptions: [], MemberLoadingFlag: true });
    var url = Api_url + `/api/district-options`;
    console.log("url=" + url + ", ElementId=" + ElementId);
    console.log("url=" + url);
    var postData = {
      additional: {
        UserInterface: 1,
        StatesCode: ElementId,
      }
    };
    console.log("postData=" + JSON.stringify(postData));
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        console.log("options=" + JSON.stringify(options));
        this.setState({ DistrictIdOptions: options, MemberLoadingFlag: false });
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  getCityIdOptions = async (e) => {
    console.log("getCityIdOptions called ");
    var DistrictId = e.value;
    this.setState({ DistrictId: DistrictId, CityId: '', CityIdOptions: [], MemberLoadingFlag: true });
    var url = Api_url + `/api/city-options`;
    console.log("url=" + url + ", DistrictId=" + DistrictId);
    var postData = {
      additional: {
        UserInterface: 2,
        DistrictCode: DistrictId,
      }
    };
    console.log("postData=" + JSON.stringify(postData));
    await axios
      .post(url, postData, axiosConfig)
      .then(res => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));
        var result = res.data.records;
        console.log("result=" + JSON.stringify(result));
        var options = result.map(d => ({
          value: d.value,
          label: d.text,
        }));
        console.log("options=" + JSON.stringify(options));
        this.setState({ CityIdOptions: options, MemberLoadingFlag: false });
        // this.setState({ isLoading: false });
        // this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  };


  componentDidMount() {
    console.log("componentDidMount called ");
    // window.location.replace('https://www.google.com')
    this.getAllTitles();
    this.getStateOptions();
    this.getZones();
  }
  render() {
    return (
      <div className="container">
        <div className="row donate-content-wrap">
          <div className="col-lg-12 text-center">
            {this.state.LoadingFlag ? <img src={LoadingGif1} alt="" width="300px" /> : ''}
          </div>
          {
            this.state.LoadingFlag ? '' :
              <div className="col-lg-12">
                <div className="donate-item">
                  {/* <button className="theme-btn submit__btn" type="button" onClick={this.makePayment} >
                    Pay Now
                  </button> */}
                  <h3 className="donate__title">Select Your Title</h3>
                  <div className="form-shared">
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="yellow-form">
                            <Select
                              options={this.state.TitleOptions}
                              name="YearwiseTitleId"
                              onChange={this.getTitleDetails.bind(this)}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="blog-inner-content">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <br />
                            {this.state.TitleLoadingFlag ? <img src={LoadingGif3} alt="" width="100px" /> : ''}
                          </div>
                          <div className="col-lg-12">
                            <br />
                            <h3 className="donate-form">
                              {this.state.TitleDescription}
                            </h3>
                            <br />
                            <h3 className="donate-form">
                              {this.state.TitleAmount}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="donate-item">
                  <h3 className="donate__title">Enter the donor's information below</h3>
                  <div className="form-shared">
                    <form action="#" method="post" id="contactForm" onSubmit={this.validateForm}>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <label for="ZoneId">
                              <span class="text-danger">*</span> Zone
                            </label>
                            <Select
                              options={this.state.ZoneOptions}
                              name="ZoneId"
                              onChange={this.fetchLoms.bind(this)}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <label for="LomId">
                              <span class="text-danger">*</span> Nominating LO Name
                            </label>
                            <Select
                              options={this.state.LomOptions}
                              name="LomId"
                              onChange={(event) => this.setState({ LomId: event.value })}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <label for="MemberFirstName">
                              <span class="text-danger">*</span> First Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              id="MemberFirstName"
                              name="MemberFirstName"
                              value={this.state.MemberFirstName}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <label for="MemberLastName">
                              <span class="text-danger">*</span> Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              id="MemberLastName"
                              name="MemberLastName"
                              value={this.state.MemberLastName}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <label for="EmailId">
                              <span class="text-danger">*</span> Email Address
                            </label>
                            <input
                              required
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              id="EmailId"
                              name="EmailId"
                              value={this.state.EmailId}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <label for="MobileNo">
                              <span class="text-danger">*</span> Mobile Number
                            </label>
                            <input
                              required
                              type="number"
                              className="form-control"
                              placeholder="Mobile Number"
                              id="MobileNo"
                              name="MobileNo"
                              value={this.state.MobileNo}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <label for="Address">
                            <span class="text-danger">*</span> Address
                          </label>
                          <textarea
                            required
                            className="textarea"
                            name="message"
                            placeholder="Address"
                            id="Address"
                            name="Address"
                            value={this.state.Address}
                            onChange={this.handleChange.bind(this)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <label for="Area">
                            Area
                          </label>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Area"
                              id="Area"
                              name="Area"
                              value={this.state.Area}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <div className="form-group">
                            <label for="StatesId">
                              <span class="text-danger">*</span> States
                            </label>
                            <Select
                              options={this.state.StatesIdOptions}
                              name="StatesId"
                              onChange={this.getDistrictIdOptions.bind(this)}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <div className="form-group">
                            <label for="DistrictId">
                              <span class="text-danger">*</span> District
                            </label>
                            <Select
                              options={this.state.DistrictIdOptions}
                              name="DistrictId"
                              onChange={this.getCityIdOptions.bind(this)}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <div className="form-group">
                            <label for="CityId">
                              <span class="text-danger">*</span> City
                            </label>
                            <Select
                              options={this.state.CityIdOptions}
                              name="CityId"
                              onChange={(event) => this.setState({ CityId: event.value })}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <div className="form-group">
                            <label for="Pincode">
                              <span class="text-danger">*</span> Pincode
                            </label>
                            <input
                              required
                              type="number"
                              className="form-control"
                              placeholder="Pincode"
                              id="Pincode"
                              name="Pincode"
                              value={this.state.Pincode}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <div className="form-group">
                            <label for="AadharNumber">
                              Aadhar Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Aadhar Number"
                              id="AadharNumber"
                              name="AadharNumber"
                              value={this.state.AadharNumber}
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <br />
                          <p> As per the new income tax rule, IF you are interested to claim 80G benefit of your donation, under income tax act, then PAN number is mandatory)</p>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <br />
                          <div className="form-group">
                            <label>
                              Do you have Pan Number?
                            </label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="PanNumberStatus" value={1} checked={this.state.PanNumberStatus == 1 ? true : false} onChange={this.handleChange.bind(this)} />
                              <label class="form-check-label">
                                Yes
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="PanNumberStatus" value={0} checked={this.state.PanNumberStatus == 0 ? true : false} onChange={this.handleChange.bind(this)} />
                              <label class="form-check-label">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        {this.state.PanNumberStatus == 1 ?
                          <div className="col-lg-6 col-sm-6">
                            <br />
                            <div className="form-group">
                              <label for="PanNumber">
                                <span class="text-danger">*</span> PAN Number
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                placeholder="PAN Number"
                                id="PanNumber"
                                name="PanNumber"
                                value={this.state.PanNumber}
                                onChange={this.handleChange.bind(this)}
                              />
                            </div>
                          </div> : ''}
                        {this.state.PanNumberStatus == 1 ?
                          <div className="col-lg-6 col-sm-6">
                            <br />
                            <div className="form-group">
                              <label>
                                Do you want to claim income tax?
                              </label>
                              <br />
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="PanCardStatus" value={1} checked={this.state.PanCardStatus == 1 ? true : false} onChange={this.handleChange.bind(this)} />
                                <label class="form-check-label">
                                  Yes
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="PanCardStatus" value={0} checked={this.state.PanCardStatus == 0 ? true : false} onChange={this.handleChange.bind(this)} />
                                <label class="form-check-label">
                                  No
                                </label>
                              </div>
                            </div>
                          </div> : ''}
                        {this.state.PanCardStatus == 1 && this.state.PanNumberStatus == 1 ?
                          <div className="col-lg-6 col-sm-6">
                            <br />
                            <div className="form-group">
                              <label>
                                Pan Card Image
                              </label>
                              <input
                                required
                                type="file"
                                className="form-control-file"
                                id="PanCardImage"
                                name="PanCardImage"
                                onChange={this.onFileChange}
                              />
                            </div>
                          </div> : ''}
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <button type="submit" className="theme-btn submit__btn">
                          pay now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="text-success">{this.state.SuccessMsg}</p>
                    <p className="text-danger">{this.state.ErrorMsg}</p>
                  </div>
                </div>
              </div>
          }
          <div className="col-lg-12 text-center">
            {this.state.SubmitFlag ? <img src={LoadingGif2} alt="" width="200px" /> : ''}
            {this.state.PaymentLoadingFlag ? <img src={LoadingGif4} alt="" width="200px" /> : ''}
          </div>
        </div>
      </div>
    );
  }
};

export default NonMemberDonate;
